.resp-table {
  width: 100%;
  display: table;
}
.resp-table .resp-table-caption {
  display: table-caption;
  font-weight: bold;
}
.resp-table .resp-table-header {
  display: table-header-group;
  font-weight: bold;
}
.resp-table .table-header-cell {
  display: table-cell;
  padding: 5px 0px 5px 0px;
  text-align: justify;
  border-bottom: 1px solid black;
}
.resp-table .resp-table-body {
  display: table-row-group;
}
.resp-table .resp-table-row {
  display: table-row;
}
.resp-table .resp-table-body .resp-table-row:nth-child(odd) {
  background: var(--table-odd-row-color);
}
.resp-table .resp-table-body .resp-table-row.odd {
  background: var(--table-odd-row-color);
}
.resp-table .resp-table-body .resp-table-row.even {
  background: unset;
}
.resp-table .table-body-cell {
  display: table-cell;
  padding: 2px;
}
.resp-table .resp-table-footer {
  display: table-footer-group;
  background-color: gray;
  font-weight: bold;
  font-size: 25px;
  color: rgba(255, 255, 255, 0.45);
}
.resp-table .table-footer-cell {
  display: table-cell;
  padding: 10px;
  text-align: justify;
  border-bottom: 1px solid black;
}
