.header-outer-spacer {
  height: 105px;
  position: relative;
  z-index: 100;
}
.header-outer {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 105px;
  background: var(--primary-color);
  border-bottom: 1px solid var(--text-color);
}
.header-outer .barUnderHeader {
  padding-top: 5px;
  text-align: right;
  font-weight: bold;
}
.header-outer .barUnderHeader .leftDiv {
  display: block;
}
.header-outer .barUnderHeader .leftDiv .logout {
  padding-left: 5px;
  cursor: pointer;
  color: var(--primary-color);
}
.header-outer .barUnderHeader .rightDiv {
  display: none;
  text-align: right;
}
.header-outer .barUnderHeader a,
.header-outer .barUnderHeader a:visited,
.header-outer .barUnderHeader a:active {
  color: var(--primary-color);
  text-decoration: none;
}
.header-outer .barUnderHeader a:hover {
  opacity: 0.7;
}
header {
  height: 105px;
}
header .centerContainer {
  display: flex;
  justify-content: space-between;
  height: 104px;
}
header .centerContainer .logo-outer {
  flex: 1;
  padding-top: 15px;
}
body::before {
  content: '';
  background: url('./headerLogoAnim.gif');
}
header .centerContainer .logo-outer img:hover {
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: url('./headerLogoAnim.gif') no-repeat;
  width: 241px;
  height: 69px;
  padding-left: 241px;
}
header .centerContainer .rightHamburgerDiv {
  display: block;
  flex: 0 1;
  color: var(--text-color);
  display: flex;
  justify-content: center;
}
header .centerContainer .rightHamburgerDiv .navDrawerBtnDiv {
  align-self: center;
}
header .centerContainer .rightDiv {
  display: none;
  flex: 1;
  text-align: right;
  font-weight: bold;
  font-size: 16px;
  color: var(--text-color);
  position: relative;
}
header .centerContainer .rightDiv > div {
  position: relative;
  top: 110px;
}

@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
  header .centerContainer .rightHamburgerDiv {
    display: none;
  }
  header .centerContainer .rightDiv {
    display: block;
  }
  header .centerContainer {
    background-image: url('./rightHeaderBG.png');
    background-size: auto 104px;
    background-position: top right;
    background-repeat: no-repeat;
  }
  .header-outer .barUnderHeader .rightDiv {
    display: block;
    text-align: right;
  }
  .header-outer {
    padding-left: 85px;
  }
  .header-outer .barUnderHeader {
    text-align: right;
    font-weight: unset;
  }
  .header-outer .barUnderHeader .leftDiv {
    display: none;
  }
}
@media (min-width: 1200px) {
}
