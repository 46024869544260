#userprofile_accountsettings .sec1 .inputDiv {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 14px;
}
#userprofile_accountsettings .sec1 div input {
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid var(--content-border-color);
}
#userprofile_accountsettings .sec1 div .inputError {
  color: red;
  font-weight: normal;
  font-size: 13px;
  font-style: italic;
  margin-top: 5px;
}
#userprofile_accountsettings .sec1 .actionBtns {
  padding-top: 30px;
  margin-bottom: 10px;
  text-align: center;
}
#userprofile_accountsettings .sec1 #saveButton {
  background: var(--primary-color);
  padding: 8px 20px;
  border-radius: 10px;
  border: 1px solid var(--primary-color);
  font-weight: bold;
  cursor: pointer;
  color: var(--bg-color);
}

#userprofile_accountsettings .sec1 .actionBtns.greyOut #saveButton {
  background: var(--text-color);
  border: 1px solid var(--text-color);
  color: var(--bg-color);
  opacity: 0.2;
  cursor: not-allowed;
}
#userprofile_accountsettings .sec1 .formErrorMsg,
#userprofile_accountsettings .sec1 .formSuccessMsg {
  font-weight: bold;
  font-size: 13px;
  text-align: center;
  margin-top: 15px;
  position: relative;
  top: 10px;
}
#userprofile_accountsettings .sec1 .formSuccessMsg {
  color: green;
}
#userprofile_accountsettings .sec1 .formErrorMsg {
  color: red;
  font-style: italic;
}
