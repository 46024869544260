#ProdFuzzyMatch .white_box {
  flex: 1;
  padding: 20px 20px;
}
#ProdFuzzyMatch .preHeading {
  font-size: 13px;
  color: var(--text-color);
}
#ProdFuzzyMatch .titleDiv {
  border-bottom: 1px solid var(--content-border-color);
  font-size: 18px;
  color: var(--content-border-darkerColor);
  margin-bottom: 5px;
}
#ProdFuzzyMatch .titleDiv {
  padding-right: 10px;
  color: var(--primary-color);
}
#ProdFuzzyMatch .titleDiv.flex {
  display: flex;
  justify-content: space-between;
}
#ProdFuzzyMatch .subHeading {
  margin-bottom: 30px;
  opacity: 0.8;
}
#ProdFuzzyMatch .secTitle {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}
#ProdFuzzyMatch
  .singleProduct
  .content
  .singleProductSearch-outer
  input[type='text'] {
  flex: 1;
  background: var(--bg-color);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border: 1px solid var(--text-color);
  height: 25px;
  padding-left: 5px;
}
#ProdFuzzyMatch
  .singleProduct
  .content
  .singleProductSearch-outer
  #searchBarPartNumber {
  flex: 0 0.2;
  background: var(--bg-color);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border: 1px solid var(--text-color);
  height: 25px;
  padding-left: 5px;
}

#ProdFuzzyMatch
  .singleProduct
  .content
  .singleProductSearch-outer
  input[type='select'] {
  border: 1px solid var(--text-color);
  background: var(--bg-color);
  border-radius: 0px;
  height: 25px;
}
#ProdFuzzyMatch .singleProduct .singleProductSearch-outer button {
  flex: 0 0 100px;
  background: var(--primary-color);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 1px solid var(--primary-color);
  color: var(--bg-color);
  height: 25px;
  cursor: pointer;
}
#ProdFuzzyMatch .singleProduct .singleProductSearch-outer button:hover {
  opacity: 0.7;
}
#ProdFuzzyMatch .singleProduct .pricingInfoInputs {
  padding-top: 10px;
}
#ProdFuzzyMatch .singleProduct .pricingInfoInputs .title {
  font-weight: bold;
  padding-top: 5px;
}
#ProdFuzzyMatch .singleProduct .pricingInfoInputs .label {
  display: inline-block;
  width: 80px;
}
#ProdFuzzyMatch .searchAsTypeAnchor-outer {
  position: relative;
}
#ProdFuzzyMatch .searchAsTypeAnchor-outer.hide {
  display: none;
}
#ProdFuzzyMatch .searchAsTypeAnchor {
  background: white;
  position: absolute;
  top: -1;
  width: 100%;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 1px solid #0d2c54;
  line-height: 2em;
  padding-left: 5px;
  color: black;
  z-index: 25;
}
#ProdFuzzyMatch .searchAsTypeAnchor.hide {
  display: none;
}
#ProdFuzzyMatch .searchAsTypeAnchor div {
  font-weight: bold;
  cursor: pointer;
}
#ProdFuzzyMatch .searchAsTypeAnchor div:hover {
  background: #dcebfd;
}
#ProdFuzzyMatch .singleProduct .singleSearchAdvancedOptions-outer.hide {
  display: none;
}
#ProdFuzzyMatch .singleProduct .singleSearchAdvancedOptions-outer {
}
#ProdFuzzyMatch .singleProduct .options-flex-outer {
}
#ProdFuzzyMatch .singleProduct .options-flex {
  padding-top: 10px;
  flex: 0 1 50%;
}
#ProdFuzzyMatch .singleProduct .options-flex .label {
  flex: 0 0 150px;
}
#ProdFuzzyMatch .singleProduct .options-flex select {
  width: 100%;
}
#ProdFuzzyMatch .singleProduct .toggleAdvancedOptions {
  padding-top: 20px;
  text-align: right;
}
#ProdFuzzyMatch .singleProduct .toggleAdvancedOptions span {
  cursor: pointer;
  color: blue;
}
#ProdFuzzyMatch .singleProduct .pricingInfoInputs .pricingInput-inner-item {
  padding-top: 10px;
}
#ProdFuzzyMatch
  .singleProduct
  .pricingInfoInputs
  .pricingInput-inner-item
  .label {
  flex: 0 0 100px;
  align-self: center;
}
#ProdFuzzyMatch .singleProduct .pricingInfoInputs .fullWidth {
  width: 100%;
}
#ProdFuzzyMatch .singleProduct .singleProdSearchResult-outer {
  padding-top: 15px;
}
#ProdFuzzyMatch .singleProduct .searchProd_title-outer .secFeedback {
  font-size: 14px;
  font-weight: bold;
  background: var(--table-odd-row-color);
  border-radius: 5px;
  padding: 13px 10px 10px 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  opacity: 0.7;
}
#ProdFuzzyMatch .singleProduct .searchProd_title-outer .secTitle {
  padding-top: 3px;
}
#ProdFuzzyMatch
  .singleProduct
  .searchProd_title-outer
  .secFeedback
  .secFeedback-float {
  display: flex;
  font-size: 18px;
  padding-left: 10px;
  position: relative;
  top: -2px;
}
#ProdFuzzyMatch
  .singleProduct
  .searchProd_title-outer
  .secFeedback
  .secFeedback-float
  .far {
  margin-left: 10px;
  cursor: pointer;
}
#ProdFuzzyMatch
  .singleProduct
  .searchProd_title-outer
  .secFeedback
  .secFeedback-float
  .fa-thumbs-up:hover {
  color: green;
}
#ProdFuzzyMatch
  .singleProduct
  .searchProd_title-outer
  .secFeedback
  .secFeedback-float
  .fa-thumbs-down:hover {
  color: red;
}

#ProdFuzzyMatch .singleProduct .resultTable-outer {
  margin-top: 15px;
}
#ProdFuzzyMatch .singleProduct .resultTable-outer table {
  width: 100%;
}
#ProdFuzzyMatch .singleProduct .resultTable-outer table tbody tr {
  cursor: pointer;
}

#ProdFuzzyMatch .singleProduct .resultTable-outer table .pricingLoading {
  text-align: center;
  padding: 20px;
}
#ProdFuzzyMatch .singleProduct .singleSearch_itemDetails {
  padding: 10px;
  text-align: left;
}
#ProdFuzzyMatch .singleProduct .singleSearch_itemDetails .supplierInfo {
  margin-bottom: 0px;
  padding: 5px 0px 0px 0px;
  font-weight: bold;
}
#ProdFuzzyMatch .singleProduct .singleSearch_itemDetails .priceInfo {
  margin-bottom: 5px;
  padding: 5px 0px;
  border-bottom: 1px solid var(--content-border-color);
  font-weight: bold;
}

#ProdFuzzyMatch .item_availability {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
#ProdFuzzyMatch .item_availability .loc-outer {
  flex: 25% 0;
  display: flex;
}
#ProdFuzzyMatch .item_availability .loc-qty {
  flex: 0 0 30px;
}

#ProdFuzzyMatch .uploadBulk .leftDiv {
  flex: 1;
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid var(--text-color);
  line-height: 1.5em;
}
#ProdFuzzyMatch .uploadBulk .leftDiv div.marginBottom {
  margin-bottom: 25px;
}
#ProdFuzzyMatch .uploadBulk .leftDiv .bulkForm {
  position: relative;
}
#ProdFuzzyMatch .uploadBulk .leftDiv .bulkForm.formLoadingOpacity {
  opacity: 0.1;
}
#ProdFuzzyMatch .uploadBulk .leftDiv .formLoadingSpinner-outer {
  position: relative;
  top: -175px;
}
#ProdFuzzyMatch .uploadBulk .leftDiv .formLoadingSpinner-outer.hidden {
  display: none;
}
#ProdFuzzyMatch .uploadBulk .leftDiv .bulkForm input[type='text'],
#ProdFuzzyMatch .uploadBulk .leftDiv .bulkForm input[type='file'] {
  width: 100%;
  border: 1px solid var(--content-border-color);
  padding: 4px 5px;
  margin-top: 5px;
  border-radius: 3px;
}

#ProdFuzzyMatch .uploadBulk .leftDiv .bulkPricing-inner-item {
  display: flex;
  justify-content: space-evenly;
}
#ProdFuzzyMatch .uploadBulk .leftDiv .bulkPricing-inner-item label {
  flex: 0 0 100px;
  align-self: center;
}
#ProdFuzzyMatch .uploadBulk .leftDiv .bulkPricing-inner-item .fullWidth {
  width: auto;
  flex: 1;
}
#ProdFuzzyMatch .uploadBulk .leftDiv input[type='submit'] {
  padding: 4px 15px;
  background: var(--primary-color);
  color: var(--bg-color);
  border-radius: 3px;
  border: 0px;
  cursor: pointer;
  width: 100%;
  margin-top: 30px;
}
#ProdFuzzyMatch .uploadBulk .leftDiv submit:hover {
  opacity: 0.7;
}
#ProdFuzzyMatch .uploadBulk .leftDiv .sumbitErrorMsg {
  color: var(--danger-color);
  margin-bottom: 5px;
  font-style: italic;
}
#ProdFuzzyMatch .uploadBulk .leftDiv .formSuccessDiv {
  text-align: center;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}
#ProdFuzzyMatch .uploadBulk .leftDiv .formSuccessDiv .leftDiv {
  border: 0px;
  flex: 0 1;
  margin-right: 15px;
}
#ProdFuzzyMatch .uploadBulk .leftDiv .formSuccessDiv .rightDiv {
  flex: 0.8;
  line-height: 5px;
}
#ProdFuzzyMatch .uploadBulk .leftDiv .formSuccessDiv .rightDiv .title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}
#ProdFuzzyMatch .uploadBulk .leftDiv .formSuccessDiv .rightDiv .subTitle {
  font-size: 20px;
  margin-bottom: 0px;
}
#ProdFuzzyMatch .uploadBulk .leftDiv .formSuccessDiv .rightDiv .subsubTitle {
  margin-bottom: 10px;
  font-size: 12px;
}
#ProdFuzzyMatch .uploadBulk .leftDiv .formSuccessDiv .fas {
  opacity: 0.4;
}
#ProdFuzzyMatch
  .uploadBulk
  .leftDiv
  .formSuccessDiv
  .clearBulkFuzzySuccessForm {
  color: blue;
  cursor: pointer;
  width: auto;
  text-decoration: underline;
  margin-top: 7px;
}
#ProdFuzzyMatch .uploadBulk .leftDiv .tableDisplay a,
#ProdFuzzyMatch .uploadBulk .leftDiv .tableDisplay a:visited,
#ProdFuzzyMatch .uploadBulk .leftDiv .tableDisplay a:active {
  text-decoration: none;
  color: var(--primary-color);
}
#ProdFuzzyMatch .uploadBulk .leftDiv .tableDisplay .deleteProject {
  cursor: pointer;
  color: var(--primary-color);
}
#ProdFuzzyMatch .uploadBulk .leftDiv .tableDisplay a:hover,
#ProdFuzzyMatch .uploadBulk .leftDiv .tableDisplay .deleteProject:hover {
  opacity: 0.7;
}
#ProdFuzzyMatch .uploadBulk .rightDiv {
  flex: 0.6;
}
#ProdFuzzyMatch .uploadBulk .rightDiv h3 {
  margin-top: 0px;
}
#ProdFuzzyMatch .uploadBulk .rightDiv img {
  max-width: 100%;
  height: auto;
}
.desktopOnly,
.desktopOnlyCell,
.desktopOnlyRow {
  display: none;
}
#ProdFuzzyMatch .p_top_img {
  flex: 0 1 40%;
  display: flex;
}
#ProdFuzzyMatch .p_top_img .p_small_imgs {
  border: 1px solid black;
  display: block;
  cursor: pointer;
}
#ProdFuzzyMatch .p_top_img .p_small_imgs,
#ProdFuzzyMatch #p_side_img_prew .imgShadow {
  width: 75px;
}
#ProdFuzzyMatch #p_side_img_prew .imgShadow {
  margin-bottom: 10px;
}
#ProdFuzzyMatch #p_side_img_main .imgShadow {
  width: 100%;
}

#ProdFuzzyMatch .singleProduct .singleProductSearch-outer input,
#ProdFuzzyMatch .singleProduct .singleProductSearch-outer button {
  width: 100%;
  margin-bottom: 5px;
}

@media (min-width: 576px) {
  .desktopOnly {
    display: inherit;
  }
  .desktopOnlyCell {
    display: table-cell;
  }
  .desktopOnlyRow {
    display: table-row;
  }
  .mobileOnly {
    display: none;
  }
}
@media (min-width: 768px) {
  #ProdFuzzyMatch .singleProduct .pricingInfoInputs .pricingInput-inner-item {
    display: flex;
    justify-content: space-evenly;
  }
  #ProdFuzzyMatch .singleProduct .options-flex {
    display: flex;
    justify-content: flex-start;
    flex: 0 1 50%;
  }

  #ProdFuzzyMatch .singleProduct .options-flex select {
    width: inherit;
  }

  #ProdFuzzyMatch .singleProduct .searchProd_title-outer .secFeedback {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
  }
  #ProdFuzzyMatch .singleProduct .searchProd_title-outer .secTitle {
    padding-top: 3px;
  }
  #ProdFuzzyMatch
    .singleProduct
    .searchProd_title-outer
    .secFeedback
    .secFeedback-float {
    display: flex;
    font-size: 18px;
    padding-left: 10px;
    position: relative;
    top: -2px;
  }

  #ProdFuzzyMatch .singleProduct .searchProd_title-outer {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 992px) {
  #ProdFuzzyMatch .uploadBulk .flex {
    display: flex;
    justify-content: space-between;
  }

  #ProdFuzzyMatch .singleProduct .singleProductSearch-outer {
    display: flex;
    justify-content: space-between;
  }
  #ProdFuzzyMatch .singleProduct .singleProductSearch-outer input,
  #ProdFuzzyMatch .singleProduct .singleProductSearch-outer button {
    width: unset;
    margin-bottom: 0px;
  }

  #ProdFuzzyMatch .uploadBulk .leftDiv {
    flex: 1;
    padding: 0px;
    margin: 0px;
    border: 0px;
    padding-right: 30px;
    margin-right: 30px;
    border-right: 1px solid var(--text-color);
  }

  #ProdFuzzyMatch .singleProduct .pricingInfoInputs .fullWidth {
    width: auto;
    flex: 1;
  }
}
@media (min-width: 1200px) {
}
