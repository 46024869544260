.pageHeadingDiv {
  padding: 30px 40px;
  background: url('./pageHeadingBG.png'), var(--bg-color);
  background-position: top right;
  background-repeat: no-repeat;
  margin-top: 60px;
}
.pageHeadingDiv .pageHeadingTop {
  border-bottom: 3px solid var(--primary-color);
  display: flex;
  justify-content: flex-start;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.pageHeadingDiv .pageHeadingGraphic {
  font-size: 2.1rem;
  opacity: 0.6;
  margin-right: 15px;
}
.pageHeadingDiv .pageHeadingText-outer .pagePreHeading {
  font-weight: bold;
  opacity: 0.7;
}
.pageHeadingDiv .pageHeadingText-outer .pageHeading {
  font-size: 18px;
  font-weight: bold;
}
