/* Overlay Acknowledgement stuff */
#allUsersResetPassword-outer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
}
#allUsersResetPassword {
  background: white;
  padding: 30px;
  text-align: center;
  border: 1px solid var(--content-border-color);
  border-radius: 10px;
  width: 500px;
}
#allUsersResetPassword .title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}
#allUsersResetPassword .instructions {
  margin-bottom: 20px;
}
#allUsersResetPassword .text {
  margin-bottom: 20px;
}
#allUsersResetPassword .text .td {
  text-align: left;
}
#allUsersResetPassword .saveBtn {
  margin-right: 15px;
}
