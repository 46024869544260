.forgotPassPage .emailDiv {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: bold;
}
.forgotPassPage input {
  border: 1px solid var(--content-border-color);
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  margin-top: 5px;
}

.forgotPassPage #forgotButton {
  background: var(--primary-color);
  padding: 8px 20px;
  border-radius: 10px;
  border: 1px solid var(--primary-color);
  font-weight: bold;
  cursor: pointer;
  color: var(--bg-color);
}

.forgotPassPage #forgotButton:hover {
  opacity: 0.7;
}

.forgotPassPage .returnLink {
  text-align: center;
  padding-top: 20px;
}
