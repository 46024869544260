.loginPagetemp .centerContainer {
  display: flex;
  justify-content: center;
  padding: 40px 0px;
}

.loginPage .login_box {
  flex: 1;
}

.loginPage .titleDiv {
  border-bottom: 1px solid var(--content-border-color);
  font-size: 18px;
  color: var(--primary-color);
  margin-bottom: 30px;
}
.loginPage .titleDiv span {
  padding-right: 10px;
  color: var(--primary-color);
}
.loginPage .emailDiv {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: bold;
}
.loginPage .passwordDiv {
  font-size: 14px;
  font-weight: bold;
}
.loginPage input {
  border: 1px solid var(--content-border-color);
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  margin-top: 5px;
}
.loginPage .actionBtns {
  padding-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}
.loginPage #loginButton {
  background: var(--primary-color);
  padding: 8px 20px;
  border-radius: 10px;
  border: 1px solid var(--primary-color);
  font-weight: bold;
  cursor: pointer;
  color: var(--bg-color);
}

.loginPage #loginButton:hover {
  opacity: 0.7;
}
.loginPage .getStarted {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.loginPage .getStartedBtn {
  padding: 8px 20px;
  border-radius: 10px;
  border: 1px solid var(--content-border-color);
  color: black;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
}

.loginPage .getStartedBtn:hover {
  background: var(--primary-color);
  color: var(--bg-color);
}

.loginPage .forgotPassword {
  padding-top: 5px;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
