#ProdFuzzyMatch_overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 150;
}

#ProdFuzzyMatch_overlay #ProdFuzzyMatch_overlay-inner {
  position: relative;
  height: 100%;
  width: 100%;
  background: var(--bg-color);
  margin: 0 auto;
  flex: 0.8 0 0;
  border: 1px solid var(--text-color);
  border-radius: 6px;
  overflow-y: auto;
}
#ProdFuzzyMatch_overlay-inner .topTitleBar {
  background: var(--primary-color);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  color: var(--bg-color);
}
#ProdFuzzyMatch_overlay-inner .topTitleBar .topTitleBar-left {
  font-weight: bold;
}
#ProdFuzzyMatch_overlay-inner .topTitleBar .topTitleBar-right {
  cursor: pointer;
  padding-left: 10px; /*Make it bigger so its easier to click */
}
#ProdFuzzyMatch_overlay-inner .summary_sec {
  padding: 15px 10px;
}
#ProdFuzzyMatch_overlay-inner .summary_sec .actionLinks {
  flex: 0 0 200px;
  order: 2;
  text-align: right;
}
#ProdFuzzyMatch_overlay-inner .summary_sec .actionLinks ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  line-height: 1.5em;
  display: flex;
  justify-content: space-between;
}
#ProdFuzzyMatch_overlay-inner .summary_sec .actionLinks li {
  display: inline;
}
#ProdFuzzyMatch_overlay-inner .summary_sec .summary_sec-left {
  flex: 1;
  order: 1;
  padding-top: 20px;
}
#ProdFuzzyMatch_overlay-inner .summary_sec .summary_sec-left .summary_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
}
#ProdFuzzyMatch_overlay-inner
  .summary_sec
  .summary_sec-left
  .summary_info
  .topInfo {
  flex: 1;
}
#ProdFuzzyMatch_overlay-inner
  .summary_sec
  .summary_sec-left
  .summary_info
  .bottomInfo {
  flex: 0 1;
}
#ProdFuzzyMatch_overlay-inner .sec-outer {
  margin: 0px 10px;
  padding: 2px 5px;
  margin-bottom: 20px;
}
#ProdFuzzyMatch_overlay-inner .sec-outer .secTitle {
  background: var(--primary-color);
  color: var(--bg-color);
  font-weight: bold;
  padding: 2px 3px;
}

#ProdFuzzyMatch_overlay-inner .sec-outer .flex .flex-inner {
  flex: 0 1 49%;
  margin: 10px;
}
#ProdFuzzyMatch_overlay-inner .sec-outer .flex .flex-inner tr td:first-child {
  background: var(--content-border-color);
  width: 50%;
  font-weight: bold;
}
#ProdFuzzyMatch_overlay-inner
  .sec-outer
  .flex
  .flex-inner
  .supplier-inner
  tr
  td:first-child,
#ProdFuzzyMatch_overlay-inner
  .sec-outer
  .flex
  .flex-inner
  .supplier-inner
  tr
  td:nth-child(3) {
  font-weight: bold;
}
#ProdFuzzyMatch_overlay-inner
  .sec-outer
  .flex
  .flex-inner
  .supplier-inner
  tr
  td {
  width: 25%;
  background: none;
}
#ProdFuzzyMatch_overlay-inner .sec-outer .flex .flex-inner tr td {
  padding: 3px 5px;
  border: 1px solid var(--bg-color);
}
#ProdFuzzyMatch_overlay-inner .sec-outer .flex .flex-inner .supplier-heading {
  font-weight: bold;
  border-bottom: 1px solid black;
}
#ProdFuzzyMatch_overlay-inner .sec-outer .flex .flex-inner .supplier-outer {
  margin-bottom: 20px;
  padding-left: 10px;
}
#ProdFuzzyMatch_overlay-inner .sec-outer .flex .flex-inner .supplier-title {
  border-bottom: 1px solid var(--text-color);
  background: var(--content-border-color);
  padding: 2px 3px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}
#ProdFuzzyMatch_overlay-inner .sec-outer .flex .flex-inner .supplier-inner {
  padding: 0px 5px;
}
#ProdFuzzyMatch_overlay-inner .p_top_img {
  flex: 0 1 40%;
  display: flex;
}
#ProdFuzzyMatch_overlay-inner #p_side_img_prew {
  flex: 105px;
  margin-right: 10px;
  overflow-y: auto;
  max-height: 210px;
}
#ProdFuzzyMatch_overlay-inner #p_side_img_main {
  flex: 1 1 70%;
}
#ProdFuzzyMatch_overlay-inner #p_side_img_main img {
  height: 210px;
}

#ProdFuzzyMatch_overlay-inner .p_top_img .p_small_imgs {
  border: 1px solid var(--text-color);
  display: block;
  cursor: pointer;
}
#ProdFuzzyMatch_overlay-inner .p_top_img .p_small_imgs,
#ProdFuzzyMatch_overlay-inner #p_side_img_prew .imgShadow {
  width: 75px;
}
#ProdFuzzyMatch_overlay-inner #p_side_img_prew .imgShadow {
  margin-bottom: 10px;
}
#ProdFuzzyMatch_overlay-inner #p_side_img_main img {
  border: 1px solid var(--text-color);
}
#ProdFuzzyMatch_overlay-inner #p_side_img_main .imgShadow {
  width: 100%;
}
#ProdFuzzyMatch_overlay-inner .summary_sec .noImageFound {
  color: var(--text-color);
  opacity: 0.2;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
#ProdFuzzyMatch_overlay-inner .summary_sec .noImageFound .fa-images {
}
#ProdFuzzyMatch_overlay-inner .summary_info .topInfo .title {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}

#ProdFuzzyMatch_overlay-inner .summary_sec .secFeedback {
  font-size: 14px;
  font-weight: bold;
  background: var(--table-odd-row-color);
  border-radius: 5px;
  padding: 13px 10px 10px 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  opacity: 0.7;
}
#ProdFuzzyMatch_overlay-inner .summary_sec .secFeedback .secTitle {
  padding-top: 3px;
}
#ProdFuzzyMatch_overlay-inner .summary_sec .secFeedback .secFeedback-float {
  display: flex;
  font-size: 18px;
  padding-left: 10px;
  position: relative;
  top: -2px;
}
#ProdFuzzyMatch_overlay-inner
  .summary_sec
  .secFeedback
  .secFeedback-float
  .far {
  margin-left: 10px;
  cursor: pointer;
}
#ProdFuzzyMatch_overlay-inner
  .summary_sec
  .secFeedback
  .secFeedback-float
  .fa-thumbs-up:hover {
  color: green;
}
#ProdFuzzyMatch_overlay-inner
  .summary_sec
  .secFeedback
  .secFeedback-float
  .fa-thumbs-down:hover {
  color: red;
}

#ProdFuzzyMatch_overlay-inner .secContent {
  margin-bottom: 10px;
}
#ProdFuzzyMatch_overlay-inner .secContent .title {
  background: var(--content-border-color);
  padding: 2px 3px;
  font-weight: bold;
}
#ProdFuzzyMatch_overlay-inner .productAssets {
  padding: 0px 10px;
}
#ProdFuzzyMatch_overlay-inner .item_availability {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-left: 10px;
}
#ProdFuzzyMatch_overlay-inner .item_availability .loc-outer {
  flex: 50% 0;
  display: flex;
  padding: 5px 0px 5px 10px;
  background: var(--table-odd-row-color);
  border: 1px solid var(--content-border-color);
}
#ProdFuzzyMatch_overlay-inner .item_availability .loc-qty {
  flex: 0 0 30px;
}
#ProdFuzzyMatch_overlay-inner .p_top_img_single img {
  width: 100%;
  height: auto;
}
#ProdFuzzyMatch_overlay-inner .asset-flex-outer {
  display: flex;
  justify-content: space-between;
  padding: 3px;
}
#ProdFuzzyMatch_overlay-inner .asset-flex-outer:nth-child(even) {
  background: var(--table-odd-row-color);
}

@media (min-width: 576px) {
  #ProdFuzzyMatch_overlay-inner .p_top_img_single img {
    height: 210px;
    width: auto;
  }
}
@media (min-width: 768px) {
  #ProdFuzzyMatch_overlay-inner .sec-outer .flex {
    display: flex;
    justify-content: space-evenly;
  }
  #ProdFuzzyMatch_overlay-inner .sec-outer .flex .flex-inner tr td:first-child {
    width: 40%;
  }
}
@media (min-width: 992px) {
  #ProdFuzzyMatch_overlay-inner .item_availability .loc-outer {
    flex: 25% 0;
    display: flex;
  }
  #ProdFuzzyMatch_overlay {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    top: 0px;
    left: 0px;
    z-index: 150;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 65px;
  }

  #ProdFuzzyMatch_overlay #ProdFuzzyMatch_overlay-inner {
    position: relative;
    height: 80%;
    width: 80%;
    background: var(--bg-color);
    margin: 0 auto;
    flex: 0.8 0 0;
    border: 1px solid var(--text-color);
    border-radius: 6px;
  }

  #ProdFuzzyMatch_overlay-inner .summary_sec {
    display: flex;
    justify-content: space-evenly;
    padding: 15px 10px;
  }
  #ProdFuzzyMatch_overlay-inner .summary_sec .actionLinks ul {
    display: block;
  }
  #ProdFuzzyMatch_overlay-inner .summary_sec .actionLinks li {
    display: block;
  }
  #ProdFuzzyMatch_overlay-inner .summary_sec .summary_sec-left {
    flex: 1;
    order: 1;
    padding-top: 0px;
    display: flex;
    justify-content: flex-start;
  }
  #ProdFuzzyMatch_overlay-inner .summary_sec .summary_sec-left .summary_info {
    flex: 50%;
    padding-left: 20px;
    padding-top: 0px;
  }
  #ProdFuzzyMatch_overlay-inner .p_top_img_single {
    flex: 1;
  }
  #ProdFuzzyMatch_overlay-inner .p_container {
    display: flex;
    justify-content: space-evenly;
  }
}
@media (min-width: 1200px) {
}
