label.fancy-toggle {
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  cursor: pointer;
  display: block;
  margin: 0em;
  > input {
    display: none;
  }

  span {
    color: #6a759b;
  }

  i {
    display: inline-block;
    width: 48px;
    height: 22px;
    border-radius: 20px;
    vertical-align: middle;
    transition: 0.25s 0.09s;
    position: relative;
    background: #deeff7;
    &:after {
      content: " ";
      display: block;
      width: 18px;
      height: 18px;
      top: 2px;
      left: 2px;
      border-radius: 50%;
      background: #fff;
      position: absolute;
      box-shadow: 1px 2px 4px 0 rgba(#000, 0.4);
      transition: 0.15s;
    }
  }

  // Checked-state
  > input:checked + i {
    background: #1094fb;
  }

  > input:checked + i + span {
    color: #29316b;
  }

  > input:checked + i:after {
    transform: translateX(25px);
  }
}
