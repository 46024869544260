.layout_sideBar {
  display: none;
  background: url('./sideBarBG.png'), #272727;
  background-position: top left;
  background-repeat: no-repeat;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 104px;
  width: 200px;
  justify-content: space-between;
  flex-direction: column;
  transition: width 0.15s;
  transition-timing-function: ease-out;
  z-index: 40;
  box-shadow: 5px 0px 5px #aaaaaa;
}

.layout_sideBar.collapsed {
  width: 65px;
}

.layout_sideBar ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  flex: 1;
}
.layout_sideBar ul li {
  padding: 18px 0px 18px 20px;
  text-align: left;
  cursor: pointer;
  border-bottom: 1px solid var(--content-border-darkerColor);
}
.layout_sideBar ul li a,
.layout_sideBar ul li a:active,
.layout_sideBar ul li a:visited,
.layout_sideBar .bottomSymbol,
.layout_sideBar .loginSymbol a,
.layout_sideBar .loginSymbol a:visited,
.layout_sideBar .loginSymbol a:active {
  color: var(--bg-color);
  opacity: 0.6;
  text-decoration: none;
}
.layout_sideBar ul li a:hover,
.layout_sideBar ul li .fas:hover,
.layout_sideBar .bottomSymbol:hover,
.layout_sideBar .loginSymbol a:hover,
.layout_sideBar .loginSymbol:hover {
  opacity: 1;
}
.layout_sideBar ul li .fas,
.layout_sideBar .loginSymbol .fas {
  padding-right: 25px;
}
.layout_sideBar .bottomSymbol,
.layout_sideBar .loginSymbol {
  cursor: pointer;
  flex: 0 1 50px;
  border-top: 1px solid var(--content-border-darkerColor);
  padding: 20px 0px 20px 20px;
}
.layout_sideBar .adLogo {
  width: 14px;
}
.layout_sideBar.collapsed .adLogo {
  width: 26px;
  padding-top: 3px;
}

.layout_sideBar.collapsed ul li {
  text-align: center;
  padding: 12px 0px;
  font-size: 1.5rem;
}
.layout_sideBar.collapsed .bottomSymbol,
.layout_sideBar.collapsed .loginSymbol,
.layout_sideBar.collapsed ul li .fas {
  padding-right: 0px;
}
.layout_sideBar.collapsed .bottomSymbol,
.layout_sideBar.collapsed .loginSymbol {
  text-align: left;
  font-size: 1.5rem;
  text-align: center;
  padding: 10px 0px;
}
.layout_sideBar .loginSymbol {
  color: var(--bg-color);
  opacity: 0.6;
}

@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
  .layout_sideBar {
    display: flex;
  }
}
@media (min-width: 1200px) {
}
