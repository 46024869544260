#employeeDashboardPage .dashboardSec1 {
  line-height: 1.5em;
}

#employeeDashboardPage .dashboardSec1 .flex .leftDiv {
  padding-top: 3px;
  margin-bottom: 15px;
}
#employeeDashboardPage .dashboardSec1 .flex .rightDiv {
  text-align: left;
}
#employeeDashboardPage .dashboardSec1 .flexLinkContent {
  display: flex;
  justify-content: left;
}
#employeeDashboardPage .dashboardSec1 .flexLinkContent .leftDiv {
  flex: 0 1;
  font-size: 40px;
  opacity: 0.2;
  padding-right: 10px;
}
#employeeDashboardPage .dashboardSec1 .flexLinkContent .rightDiv {
  flex: 1;
}
#employeeDashboardPage .dashboardSec1 .flexLinkContent .rightDiv h3 {
  margin-top: 0px;
  margin-bottom: 3px;
}

#employeeDashboardPage .dashboardSec1 .flexLinkContent a,
#employeeDashboardPage .dashboardSec1 .flexLinkContent a:visited,
#employeeDashboardPage .dashboardSec1 .flexLinkContent a:active {
  color: var(--text-color);
  text-decoration: none;
}
#employeeDashboardPage .dashboardSec1 .flexLinkContent a:hover {
  color: var(--primary-color);
}

@media (min-width: 576px) {
}
@media (min-width: 768px) {
  #employeeDashboardPage .dashboardSec1 .flex {
    display: flex;
    justify-content: space-between;
  }
  #employeeDashboardPage .dashboardSec1 .flex .leftDiv-outer {
    flex: 1;
    margin-bottom: 0px;
    padding-top: 0px;
  }
  #employeeDashboardPage .dashboardSec1 .flex .rightDiv-outer {
    flex: 1;
    text-align: left;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
