.navDrawerBtn {
  height: 30px;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 30px;
  cursor: pointer;
  transition: height 0.5s;
}
.navDrawerBtn div {
  width: 100%;
  height: 20%;
  background: white;
  border-radius: 3px;
}
.navDrawerBtn:hover {
  height: 40px;
  transition: height 0.5s;
}
.navDrawerBtn:hover div {
  height: 20%;
  background: #00a6ed;
}
.navDrawerBtn:hover div:first-child {
  transform: rotate(180deg);
  transition: all 0.5s;
}
.navDrawerBtn:hover div:last-child {
  transform: rotate(-180deg);
  transition: all 0.5s;
}
