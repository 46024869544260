.contentBox {
  background: var(--bg-color);
  padding: 40px;
  margin-top: 10px;
}
.contentBox .blockTopTitle-outer {
  border-bottom: 1px solid var(--text-color);
  margin-bottom: 5px;
}
.contentBox .blockTopTitle {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
}
.contentBox .dateDropDown-outer {
  text-align: left;
  margin-bottom: 5px;
}
.contentBox .blockBottomDesc {
  margin-bottom: 30px;
}
.contentBox .viewAll-outer {
  padding-top: 10px;
  text-align: right;
}
.contentBox .twoCol-flex-Layout .rightDiv {
  flex: 1;
  padding-top: 40px;
}

@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .contentBox .blockTopTitle-outer {
    display: flex;
    justify-content: space-between;
  }
  .contentBox .dateDropDown-outer {
    text-align: right;
  }
}
@media (min-width: 992px) {
  .contentBox .twoCol-flex-Layout {
    display: flex;
    justify-content: space-between;
  }
  .contentBox .twoCol-flex-Layout .leftDiv {
    flex: 1;
    padding-right: 15px;
  }
  .contentBox .twoCol-flex-Layout .rightDiv {
    flex: 1;
    padding-left: 15px;
    padding-top: 0px;
  }
}
@media (min-width: 1200px) {
}
