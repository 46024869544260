#admin_allUsers .users-outer {
  margin-top: 20px;
}
#admin_allUsers .users-outer .user-outer {
  padding: 5px;
}

#admin_allUsers .users-outer .user-outer:nth-of-type(odd) {
  background: var(--table-odd-row-color);
}
#admin_allUsers .users-outer .user-outer .top {
  padding-bottom: 3px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
#admin_allUsers .users-outer .user-outer .top .green {
  color: green;
}
#admin_allUsers .users-outer .user-outer .top .red {
  color: red;
}
#admin_allUsers .users-outer .user-outer .user-detail {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  font-size: 12px;
  line-height: 1.4em;
  padding-bottom: 5px;
  border-top: 1px solid var(--content-border-color);
}
#admin_allUsers .users-outer .user-outer .user-detail .clickable {
  text-decoration: underline;
  cursor: pointer;
  color: blue;
}
#admin_allUsers .users-outer .user-outer .user-detail div:nth-of-type(even) {
  text-align: right;
}
#admin_allUsers .users-outer .user-outer .permissions-outer {
  padding-bottom: 10px;
}
#admin_allUsers .users-outer .user-outer .permissions-outer .permissions {
  display: flex;
  justify-content: flex-start;
  border-top: 1px solid var(--content-border-color);
  margin-top: 5px;
  padding: 5px 0px 5px 10px;
}
#admin_allUsers .users-outer .user-outer .permissions-outer .permissions div {
  flex: 1;
}
#admin_allUsers
  .users-outer
  .user-outer
  .permissions-outer
  .permissions
  div
  .clickable {
  cursor: pointer;
}

@media (min-width: 700px) {
  #admin_allUsers .users-outer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }
  #admin_allUsers .users-outer .user-outer:nth-of-type(odd) {
    background: unset;
  }
  #admin_allUsers .users-outer .user-outer:nth-of-type(4n + 4),
  #admin_allUsers .users-outer .user-outer:nth-of-type(4n + 3) {
    background: var(--table-odd-row-color);
  }
}
