.paginationDiv {
  display: flex;
  justify-content: center;
}
ul.pagination {
  border-radius: 5px;
  display: inline-block;
  padding-left: 0px;
  margin: 20px 0px;
}
ul.pagination {
  list-style: none;
}
ul.pagination li {
  display: inline;
}
ul.pagination li a {
  padding: 6px 14px;
  border: 1px solid var(--content-border-color);
  position: relative;
  float: left;
  text-decoration: none;
  line-height: 1.42857143;
  background: var(--bg-color);
  margin-left: -1px;
}
ul.pagination li a:hover {
  background-color: var(--table-odd-row-color);
}
ul.pagination li.active a {
  background-color: var(--primary-color);
  color: white;
}
ul.pagination li:first-child a {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
ul.pagination li:last-child a {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
