:root {
  --primary-color: #0000fe;
  --bg-color: white;
  --text-color: black;
  --content-border-color: #ccc;
  --content-border-darkerColor: #999;
  --table-odd-row-color: #d7e6f5;

  --danger-color: #dc3545;
  --success-color: #28a745;
}

/* Set 100% so modal background fills the whole screen */
body {
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
  overflow-x: hidden;
  font-size: 13px;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  background: rgb(226, 226, 226);
  background: -moz-linear-gradient(
    197deg,
    rgba(226, 226, 226, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    197deg,
    rgba(226, 226, 226, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    197deg,
    rgba(226, 226, 226, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background-repeat: no-repeat;
  background-attachment: fixed;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e2e2e2",endColorstr="#ffffff",GradientType=1);
}
html,
#App,
#root {
  height: 100%;
}
*,
::before,
::after {
  box-sizing: border-box;
}

#navBarBotomShadow {
  margin-bottom: 20px;
}

.flex_center {
  display: flex;
  justify-content: center;
}

.headerPlaceHolder {
  display: none;
}
.mobile_headerPlaceHolder {
  display: block;
}

/* Utils */

.center1100 {
  width: 1100px;
}

.css-inactive {
  opacity: 0.5;
}
.rndBtn {
  background: black;
  border-radius: 20px;
  padding: 5px 25px;
  cursor: pointer;
}

.cursorPointer {
  cursor: pointer;
}

.justifyLeft {
  justify-content: left;
}
.justifyCenter {
  justify-content: center;
}
.justifyRight {
  justify-content: right;
}

.responsive {
  width: 100%;
  height: auto;
}

/* TEXT MODIFIERS */
.nounderline {
  text-decoration: none;
}
.strike {
  text-decoration: line-through;
}
.textcenter {
  text-align: center;
}
.textright {
  text-align: right;
}
.textleft {
  text-align: left;
}
.color_main {
  color: rgb(0, 0, 0);
}
.color_white {
  color: white;
}
.color_blue {
  color: blue;
}
.color_green {
  color: green;
}
.color_red {
  color: red;
}
.color_gold {
  color: gold;
}
.color_grey {
  color: grey;
}

.boldNO {
  font-weight: normal;
}
.bold {
  font-weight: bold;
}
.bolder {
  font-weight: bolder;
}
.bold900 {
  font-weight: 900;
}

.font10 {
  font-size: 10px;
}
.font11 {
  font-size: 11px;
}
.font12 {
  font-size: 12px;
}
.font13 {
  font-size: 13px;
}
.font14 {
  font-size: 14px;
}
.font15 {
  font-size: 15px;
}
.font16 {
  font-size: 16px;
}
.font18 {
  font-size: 18px;
}
.font20 {
  font-size: 20px;
}
.font22 {
  font-size: 22px;
}
.font24 {
  font-size: 24px;
}
.font26 {
  font-size: 26px;
}
.font28 {
  font-size: 28px;
}
.font30 {
  font-size: 30px;
}
.font32 {
  font-size: 32px;
}
.font34 {
  font-size: 34px;
}
.font40 {
  font-size: 40px;
}
.font46 {
  font-size: 46px;
}

.displayNone {
  display: none;
}

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 1;
  z-index: 100;
  background: var(--light-color);
  color: #333;
  position: fixed;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.centerContainer {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.notFound404Div {
  padding: 40px 15px;
}

.notFound404Div img {
  max-width: 100%;
  height: auto;
}

@media (min-width: 576px) {
  .centerContainer {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .centerContainer {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  #root {
    background-image: url('./components/layout/allPagesLogoBG.png');
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
  }
  .headerPlaceHolder {
    display: block;
  }
  .mobile_headerPlaceHolder {
    display: none;
  }
  .centerContainer {
    max-width: 960px;
  }
  #contentWrapper {
    padding-left: 85px;
  }
}
@media (min-width: 1200px) {
  .centerContainer {
    max-width: 1200px;
  }
}

/* Animations */
/* .fadeInLeft */
@-ms-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

/* Fade In Bottom */
@-ms-keyframes fadeInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-o-keyframes fadeInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes fadeInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-moz-keyframes fadeInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInBottom {
  -webkit-animation-name: fadeInBottom;
  animation-name: fadeInBottom;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}

/* Fade In Bottom */
@-ms-keyframes fadeInTop {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-o-keyframes fadeInTop {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes fadeInTop {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-moz-keyframes fadeInTop {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInTop {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInTop {
  -webkit-animation-name: fadeInTop;
  animation-name: fadeInTop;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
}

.blinking {
  animation: blinkingText 1.2s infinite;
}
@keyframes blinkingText {
  0% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
