.side-drawer {
  height: 100%;
  background: white;
  margin: 0px;
  padding: 0px;
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 300px;
  z-index: 200;
  transform: translate(-100%);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.side-drawer.open {
  transform: translateX(0);
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
}

#sideDrawer_logo {
  max-width: 100%;
  height: auto;
  border: 0px;
}
.side-drawer .topBlueDiv {
  background: var(--primary-color);
  text-align: center;
  padding: 10px 0px;
  margin-bottom: 20px;
  flex: 0 1;
}

.side-drawer ul {
  padding: 0px 0px 0px 40px;
  margin: 0px;
  list-style: none;
}

.side-drawer li {
  margin-bottom: 20px;
  font-size: 13px;
}
.side-drawer li .icon {
  opacity: 0.25;
  width: 25px;
  display: inline-block;
}
.side-drawer li.logout {
  cursor: pointer;
}

.side-drawer a {
  color: black;
  text-decoration: none;
}
.side-drawer a:hover,
.side-drawer li.logout:hover {
  color: var(--primary-color);
}
.side-drawer .links-outer {
  flex: 1;
  overflow: auto;
}
.side-drawer .fundamentalBottom {
  flex: 0 1;
  align-self: flex-end;
  padding: 20px;
}
.side-drawer .adLogo {
  width: 12px;
}
