.FundamentalComponent .subTitle {
  font-weight: bold;
  margin-bottom: 5px;
}
.FundamentalComponent .title {
  color: var(--primary-color);
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
}
.FundamentalComponent .text {
  line-height: 1.3em;
  color: var(--text-color);
  opacity: 0.9;
}
